import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyledSlotsSectionsContainer } from '@modules/slots/components/SlotsSectionsContainer.styled';

import { SlotsScrollCards } from '@modules/slots/components/SlotsScrollCards';
import { transSlots } from '@legacyApp/client/modules/translation/translate';
import { ProvidersScrollCards } from '@modules/slots/components/ProvidersScrollCards';
import { StyledSlotContainer } from '@modules/slots/layouts/SlotCategory.layout';
import { useMainSections } from '@modules/slots/hooks/useMainPageSections';
import { updateUrlQuery } from '@legacyApp/client/store/app/app.actions';
import { Inhouse } from './Inhouse';

export const Lobby: FC = () => {
	const { firstSection: _firstSection, bottomSections: _bottomSections } =
		useMainSections();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateUrlQuery({ tab: undefined }));
	}, [dispatch]);

	return (
		<StyledSlotContainer>
			<StyledSlotsSectionsContainer>
				<Inhouse />
				{_firstSection?.category?.name && (
					<SlotsScrollCards
						name={transSlots({ label: _firstSection.category.name })}
						category={_firstSection.category}
						slots={_firstSection.games?.list || []}
						count={_firstSection.games?.length || 0}
					/>
				)}
				<ProvidersScrollCards name={transSlots({ label: 'Providers' })} />
				{!!_bottomSections.length &&
					_bottomSections?.map((section) => {
						return (
							<SlotsScrollCards
								key={section.category.id}
								name={transSlots({ label: section.category.name })}
								category={section.category}
								slots={section.games?.list || []}
								count={section.games?.length || 0}
							/>
						);
					})}
			</StyledSlotsSectionsContainer>
		</StyledSlotContainer>
	);
};
