import React, {
	FC,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '@ui/button';
import { ButtonStyled } from '@ui/button/Button.styled';
import {
	DropdownArrow,
	DropdownArrowParentMixin,
} from '@ui/dropdown/DropdownArrow';
import * as PopperJS from '@popperjs/core';
import { Check } from '@icons/ui/check';
import { InputLabelStyled } from '@ui/input/Input.styled';
import { useOnMount } from '@common/hooks/useOnMount';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { handleHover } from '@legacyApp/client/modules/style/mixins';
import { Portal } from '@common/components/portal/portal';
import { PORTAL } from '@common/components/portal/portal.constants';
import { StyledBalanceCurrency } from '@modules/balance/styles/BalanceCurrency.styled';
import { ClickOutsideWrapper } from '@legacyApp/client/components/clickOutSideWrapper';

export interface DropdownItemProps {
	id: string;
	content: ReactNode | string;
}

export interface DropdownProps {
	list: Array<DropdownItemProps>;
	defaultActiveId: DropdownItemProps['id'];
	onSelect: (id: string) => void;
	onVisibleChange?: (isVisible: boolean) => void;
	size: Size;
	forceVisible?: boolean;
	portalId?: string;
	popperOptions?: Omit<Partial<PopperJS.Options>, 'modifiers'>;
	maxHeight?: number;
	buttonProps?: ButtonProps;
	activeContent?: ReactNode;
	label?: string | ReactNode;
	disabled?: boolean;
}

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
	list,
	forceVisible,
	defaultActiveId,
	onSelect,
	onVisibleChange,
	children,
	popperOptions,
	portalId,
	buttonProps,
	activeContent,
	label,
	maxHeight,
	disabled,
}) => {
	const [visible, setVisibility] = useState(forceVisible || false);
	const [active, setActive] = useState<DropdownItemProps>(null);

	const [referenceRef, setReferenceRef] = useState<HTMLElement | null>(null);
	const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);

	const { styles, attributes, forceUpdate, update } = usePopper(
		referenceRef,
		popperRef,
		{
			placement: 'bottom-start',
			modifiers: [
				{
					name: 'offset',
					enabled: true,
					options: {
						offset: [0, 10],
					},
				},
			],
			...popperOptions,
		},
	);

	const updateVisibility = useCallback(
		(isVisible) => {
			if (disabled && isVisible) {
				return;
			}
			if (forceVisible !== undefined) {
				if (forceVisible !== visible) {
					setVisibility(forceVisible);
				}
				return;
			}
			setVisibility(isVisible);
			if (typeof forceUpdate === 'function') {
				forceUpdate();
			}
			if (isVisible === visible) {
				return;
			}
			if (typeof onVisibleChange === 'function') {
				onVisibleChange(isVisible);
			}
		},
		[disabled, forceUpdate, forceVisible, onVisibleChange, visible],
	);

	const handleDocumentClick = useCallback(
		(event) => {
			// console.log('handleDocumentClick', {
			// 	event,
			// 	visible,
			// 	popperRef: popperRef.current,
			// });
			if (!popperRef?.contains || !referenceRef?.contains) {
				return;
			}
			if (
				!popperRef.contains(event.target) &&
				!referenceRef.contains(event.target)
			) {
				updateVisibility(false);
			}
		},
		[updateVisibility],
	);

	const handleDropdownClick = useCallback(() => {
		updateVisibility(!visible);
	}, [updateVisibility, visible]);

	const handleItemClick = useCallback(
		(item: DropdownItemProps) => {
			setActive(item);
			updateVisibility(false);
			if (typeof onSelect === 'function') {
				onSelect(item?.id);
			}
		},
		[onSelect, updateVisibility],
	);

	useOnMount(() => {
		// listen for clicks and close dropdown on body
		document.addEventListener('mousedown', handleDocumentClick);

		return () => {
			document.removeEventListener('mousedown', handleDocumentClick);
		};
	});

	useEffect(() => {
		const selected_ = list.find(({ id }) => id === defaultActiveId);
		if (selected_?.id !== active?.id) {
			setActive(selected_);
		}
	}, [active?.id, defaultActiveId, list]);

	useEffect(() => {
		if (forceVisible !== undefined) {
			updateVisibility(forceVisible);
		}
	}, [forceVisible, updateVisibility]);

	if (!active?.id && defaultActiveId) {
		return null;
	}

	const dropdownMenu = (
		<ClickOutsideWrapper onClickOutside={() => updateVisibility(false)}>
			<div
				ref={setPopperRef}
				style={{
					...styles.popper,
					...(portalId &&
						visible && {
							zIndex: 999999,
							position: 'absolute',
						}),
				}}
				{...attributes.popper}
			>
				<DropdownContainer
					$maxHeight={maxHeight}
					style={styles.offset}
					$visible={visible}
				>
					{list.map((item) => {
						return (
							<DropdownItem key={item.id} onClick={() => handleItemClick(item)}>
								{item.content}
								{active?.id === item.id && (
									<DropdownArrow>
										<Check />
									</DropdownArrow>
								)}
							</DropdownItem>
						);
					})}
				</DropdownContainer>
			</div>
		</ClickOutsideWrapper>
	);

	return (
		<>
			<StyledDropdownButtonWrapper ref={setReferenceRef}>
				{label && (
					<InputLabelStyled className="text-style-label-cap">
						{label}
					</InputLabelStyled>
				)}
				{children ? (
					<span onClick={handleDropdownClick}>{children}</span>
				) : (
					<Button
						styleType={ButtonStyleType.SECONDARY}
						size={Size.LARGE}
						onClick={handleDropdownClick}
						{...buttonProps}
					>
						{activeContent !== undefined ? activeContent : active?.content}
						<DropdownArrow />
					</Button>
				)}
			</StyledDropdownButtonWrapper>

			{portalId ? (
				<Portal id={PORTAL.DROPDOWN} subId={portalId}>
					{dropdownMenu}
				</Portal>
			) : (
				dropdownMenu
			)}
		</>
	);
};

export const StyledDropdownButtonWrapper = styled.div`
	display: inline-grid;

	${ButtonStyled} {
		position: relative;
		width: 100%;

		&:focus {
			box-shadow: 0 0 0 1px var(--color-blue-brand-blue) inset;
		}

		${DropdownArrowParentMixin};
	}

	:has(${StyledBalanceCurrency}) {
		${ButtonStyled} {
			padding-bottom: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
`;

const DropdownContainer = styled.div<{
	$visible?: boolean;
	$maxHeight?: number;
}>`
	background-color: var(--color-dark-700);
	border-color: transparent;
	border-radius: 8px;
	box-shadow: 0 1px 5px var(--color-dark-900);
	display: ${(props) => (props.$visible ? 'flex' : 'none')};
	flex-direction: column;
	${(props) =>
		props.$maxHeight
			? css`
					max-height: ${props.$maxHeight}px;
					overflow: auto;
			  `
			: css`
					overflow: hidden;
			  `};
`;

const DropdownItem = styled.div`
	align-items: center;
	color: var(--color-white);
	cursor: pointer;
	justify-content: flex-start;
	position: relative;

	> *:first-child {
		display: grid;
		grid-auto-flow: column;
		padding: 12px 35px 12px 12px;
	}

	${handleHover(css`
		background-color: var(--color-dark-500);
	`)}
`;
