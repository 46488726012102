import { isFunction } from '@common/methods/isFunction';
import { SlotCategoryDTO } from '../store/slots.types';
import { SLOTS_SCROLL_CARD_COUNT } from '../constants/Slots.constants';

export const getMainPageCategories = async (
	getSlots,
	categories: SlotCategoryDTO[],
	newGames,
) => {
	const sections = [];
	for (const category of categories) {
		const games = isFunction(getSlots)
			? await getSlots({
					count: SLOTS_SCROLL_CARD_COUNT,
					categoryId: category.id,
			  })
			: { list: [{}] };
		if (games?.list?.length) {
			// console.log('getMainPageCategories - push', category, games);
			sections.push({
				games,
				category,
			});
		}
	}

	if (newGames?.list?.length) {
		sections.splice(4, 0, {
			games: newGames,
			category: {
				id: null,
				name: 'New Games',
				slug: 'new-games',
				show_in_menu: true,
				created_at: null,
			},
		});
	}

	// console.log('getMainPageCategories', sections, { categories, newGames });

	const [firstSection, ...bottomSections] = sections.filter(
		(section) => !!section.games?.list?.length,
	);

	return {
		bottomSections: bottomSections || [],
		firstSection: firstSection || {},
	};
};
