import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from '@ui/button';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { Size } from '@uiTypes/Size';
import { LinkTo } from '../../default/LinkTo/LinkTo';
import { ScrollCards, ScrollCardsList } from './ScrollCards';

interface ScrollCardsWithTitleProps {
	name?: string | ReactNode;
	href?: string;
	list?: ScrollCardsList;
	count?: number;
	noList?: boolean;
	loaded?: boolean;
	noDataContent?: ReactNode;
}

export const StyledScrollCardsWithTitle = styled.div<{
	$isNavButton?: boolean;
}>`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-bottom: 48px;

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		margin-bottom: 36px;
	}
`;

export const StyledScrollCardsHeader = styled.div`
	border-bottom: 1px solid var(--color-dark-500);
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-between;
	margin-bottom: 20px;
`;

export const StyledScrollCardsTitle = styled.div`
	margin-bottom: 22px;
`;

const StyledScrollCardsNavigation = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: flex-end;
	margin-right: 85px;
`;

export const ScrollCardsWithTitle: FC<
	PropsWithChildren<ScrollCardsWithTitleProps>
> = ({ children, name, href, count, list, noList, loaded, noDataContent }) => {
	const nameComponent = (
		<StyledScrollCardsTitle className="text-style-xl-medium">
			{name}
		</StyledScrollCardsTitle>
	);

	const isNavButton = !!href && !!count;

	return (
		<StyledScrollCardsWithTitle $isNavButton={isNavButton}>
			{name && (
				<StyledScrollCardsHeader>
					{href ? (
						<LinkTo href={href}>
							<a>{nameComponent}</a>
						</LinkTo>
					) : (
						nameComponent
					)}
					{isNavButton && (
						<StyledScrollCardsNavigation>
							<LinkTo href={href}>
								<a>
									<Button
										label={trans({ label: 'View all' })}
										className="margin-left-8"
										size={Size.MEDIUM}
										styleType={ButtonStyleType.SECONDARY}
									/>
								</a>
							</LinkTo>
						</StyledScrollCardsNavigation>
					)}
				</StyledScrollCardsHeader>
			)}
			{!noList && (
				<ScrollCards
					list={list}
					loaded={loaded}
					noDataContent={noDataContent}
				/>
			)}
			{children}
		</StyledScrollCardsWithTitle>
	);
};
