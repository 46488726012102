import styled, { css } from 'styled-components';
import { HTMLAttributes } from 'react';
import {
	DropdownArrowParentMixin,
	DropdownArrowStyled,
} from '@ui/dropdown/DropdownArrow';
import { currencyLogoStyle } from '@legacyApp/client/modules/style/mixins/currencyLogoStyle';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';

interface BalanceCurrencyStyledProps extends HTMLAttributes<HTMLDivElement> {
	name?: string;
}

export const StyledBalanceCurrency = styled.div.attrs<BalanceCurrencyStyledProps>(
	() => ({
		className: 'balance__currency',
	}),
)<BalanceCurrencyStyledProps>`
	align-items: center;
	cursor: pointer;
	display: flex !important;
	flex-direction: row;
	justify-content: space-between;
	padding: 12px 35px 12px 12px;
	transition: ${(props) => props.theme.layout.transition};
	white-space: nowrap;
	position: relative;

	${DropdownArrowStyled} {
		right: 10px;
	}

	.balance__currency--margin {
		margin-right: 4px;
	}

	&.is-table-row {
		display: table-row;
	}

	.currency-logo {
		margin-right: 20px;
	}

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media.maxWidthTablet,
						b: viewportPadding,
					})}) {
				.currency-logo {
					${currencyLogoStyle({ size: '15px', important: true })}
				}
			}
		`,
	)}
`;

export const StyledActiveBalanceCurrency = styled(StyledBalanceCurrency)`
	background-color: var(--color-dark-900);
	height: 100%;
	padding: 7.5px 35px 7.5px 12px;
	${DropdownArrowParentMixin};

	${DropdownArrowStyled} {
		right: 5px;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_large}) {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_medium}) {
		padding-top: 8.5px;
		padding-bottom: 8.5px;
	}
`;
